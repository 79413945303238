<template>
  <v-container
    fluid
    class="px-0 py-0">
    <v-form
      ref="formPersonal"
      v-model="valid"
      lazy-validation>
      <v-layout
        row
        wrap>
        <v-flex
          xs12
          sm12
          md12>
          <v-autocomplete
            v-model="selected"
            item-text="name"
            :label="label "
            :items="events"
            :menu-props="{contentClass: dataCy}"
            :data-cy="dataCy"
            :rules="multipleRules"
            return-object
            @change="change" />
        </v-flex>
      </v-layout>
    </v-form>
  </v-container>
</template>

<script>
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'

  export default {
    mixins: [ validate ],
    props: {
      label: {
        type: String,
        required: true
      },
      dataCy: {
        type: String,
        default: ''
      },
    },
    data: () => ({
      valid: true,
      selected: {}
    }),
    computed: {
      ...mapGetters({
        events: 'events/list',
        loading: 'events/listLoading'
      })
    },
    beforeMount () {
      const pagination = {
        page: 1,
        rowsPerPage: -1
      }
      this.$store.dispatch('events/getList', pagination)
    },
    methods: {
      change (val) {
        this.$emit('change', val)
      }
    }
  }
</script>
